<template>
  <div class="form-group">
    <label
      v-if="label"
      :style="`flex-basis: ${labelWidth}%`"
      :for="generatedId"
    >
      {{ label }}
    </label>
    <div
      class="form-group__input-container"
      :style="`flex-basis: ${inputWidth}%`"
    >
      <input
        v-if="type !== 'tel'"
        :value="modelValue"
        @input="updateValue"
        class="custom-input"
        :type="hidePassword ? type : 'text'"
        :id="generatedId"
        :placeholder="placeholder"
        :required="req"
        :error="name"
        tabindex="0"
        v-maska="maska"
        :min="minValue"
        :max="maxValue"
        :disabled="disabled"
        autocomplete
      />
      <vue-tel-input
        v-else
        v-model="tel"
        :value="modelValue"
        @on-input="updateValueTel"
        class="custom-input form-control__tel"
        :error="name"
        :disabled="disabled"
      ></vue-tel-input>
      <button
        type="button"
        @click="clearInput"
        v-if="cleanable && (modelValue || tel)"
        class="form-group__clean-btn"
      >
        <img src="@/assets/images/icons/close.svg" alt="Очистить форму" />
      </button>
      <button
        type="button"
        @click="hidePassword = !hidePassword"
        v-if="hideble && type === 'password' && modelValue"
        class="form-group__clean-btn eye"
      >
        <i v-if="!hidePassword" class="fal fa-eye-slash"></i>
        <i v-else class="fal fa-eye"></i>
      </button>
      <errors :field="name" :errors="errors" />
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, ref, toRef } from "@vue/runtime-core";
import Errors from "../../Technical/Errors.vue";
import { useStore } from "vuex";
export default {
  name: "custom-input",
  components: { Errors },
  props: {
    modelValue: {
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    req: {
      default: false,
    },
    maska: {
      type: String,
      default: null,
    },
    minValue: {
      type: Number,
      default: null,
    },
    maxValue: {
      type: Number,
      default: null,
    },
    disabled: {
      default: false,
    },
    delay: {
      type: Number,
      default: 0,
    },
    cleanable: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: Boolean,
      default: true,
    },
    labelWidth: {
      type: Number,
      default: 25,
    },
    inputWidth: {
      type: Number,
      default: 75,
    },
    hideble: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const currentDelay = toRef(props, "delay"),
      valueType = toRef(props, "type"),
      currentValue = toRef(props, "modelValue");
    const generatedId = ref("input"),
      tel = ref(""),
      hidePassword = ref(true);
    const errors = computed(() => store.state.errors);
    const store = useStore();
    let timerId = null;

    const generateId = () => {
      let alphabet = "abcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < 6; i++) {
        generatedId.value +=
          alphabet[Math.round(Math.random() * (alphabet.length - 1))];
      }
    };
    const updateValue = (event) => {
      if (currentDelay.value && event.target.value) {
        clearTimeout(timerId);

        timerId = setTimeout(() => {
          context.emit("update:modelValue", event.target.value);
        }, currentDelay.value);
      } else {
        context.emit("update:modelValue", event.target.value);
      }
    };
    const updateValueTel = (...data) => {
      if (data[1]?.number) {
        context.emit("update:modelValue", data[1].number);
      }
    };
    const clearInput = (e) => {
      let input = e.currentTarget.previousElementSibling;
      input.value = "";
      input.dispatchEvent(new Event("input"));
    };

    onBeforeMount(() => {
      if (valueType.value == "tel") tel.value = currentValue.value;
      generateId();
    });

    return {
      generateId,
      generatedId,
      updateValue,
      updateValueTel,
      tel,
      errors,
      currentDelay,
      timerId,
      clearInput,
      valueType,
      hidePassword,
    };
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  display: flex;
  margin: 10px -5px;
  font-size: 15px;
  font-weight: 400;
  justify-content: flex-end;
  position: relative;
  label {
    padding: 8px 15px 9px 15px;
    flex: 0 0 25%;
    word-break: break-word;
  }

  &__input-container {
    padding-right: 15px;
    flex: 0 1 75%;
    align-self: center;
  }

  &__clean-btn {
    position: absolute;
    right: 30px;
    bottom: 10px;

    &.eye{
      bottom: 11px;
      height: 19px;
      width: 19px;
    }

    img {
      width: 20px;
      height: 20px;
    }
  }

  .form-control {
    display: block;
    width: 100%;
    padding: 0.56rem 1.3rem;

    line-height: 1;
    color: #878787;
    background-color: #fff;
    background-clip: padding-box;
    border: 2px solid #ebebeb;
    // border-radius: 5px;
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;

    &:focus,
    &:focus-within {
      border-color: #89e4bf;
    }

    &__tel {
      display: flex;
    }
  }

  .custom-input::placeholder {
    color: #d3d3d3;
  }

  @media (max-width: 768px) {
    margin: 0 -5px;
  }

  @media (max-width: 973px) {
    flex-direction: column;
    align-items: flex-start;

    label {
      flex: 1;
      padding: 15px 5px;
    }
    &__input-container {
      padding: 0 5px;
      width: 100%;
      flex: 1;
    }
    .form-control {
      font-size: 16px;
    }
  }
}
</style>

<style lang="scss">
.vue-tel-input {
  &:focus-within {
    -webkit-box-shadow: unset;
    box-shadow: unset;
    border-color: unset;
  }
  .vti__dropdown {
    padding: 3px !important;
  }

  .vti__dropdown-list {
    font-weight: 200;
    border: 2px solid #89e4bf;
    &.below {
      top: 29px;
    }
  }

  .vti__dropdown-item.last-preferred {
    border-bottom: 1px solid #ebebeb;
  }
}
</style>
<template>
  <div class="error-wrap" v-if="currentErrors">
    <div v-if="currentField && checkError">
      <ul class="error-wrap__field">
        <li v-for="(error, i) in fieldError" :key="i">{{ error }}</li>
      </ul>
    </div>
    <div v-if="!currentField">
      <ul class="error-wrap__field">
        <li v-for="(error, i) in currentErrors" :key="i">{{ printArray }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { computed, toRef } from "@vue/runtime-core";

export default {
  name: "errors",
  props: ["errors", "field"],
  setup(props) {
    let currentErrors = toRef(props, "errors"),
      currentField = toRef(props, "field");

    let checkError = computed(() =>
        Object.keys(currentErrors.value).find((error) => error == currentField.value)
      ),
      fieldError = computed(() => currentErrors.value[checkError.value]),
      printArray = computed(() => {
        if (
          typeof currentErrors.value != "undefined" &&
          currentErrors.value != null &&
          currentErrors.value.length > 0 &&
          typeof currentErrors.value == "object"
        ) {
          return currentErrors.value.join(", ");
        }
        return currentErrors.value;
      });

    return {
      checkError,
      fieldError,
      printArray,
      currentErrors,
      currentField,
    };
  },
};
</script>

<style scoped lang="scss">
.error-wrap {
  z-index: 8;

  p {
    color: #959ABB;
    margin-top: 4px;
    font-size: 14px;
    line-height: 16.8px;
  }

  &__field{
    margin-top: 10px;
    margin-bottom: 0;
    padding: 15px 20px;
    position: relative;
    background-color: #f45846;
    color: #fff;
  }

  &__field::before{
    content: "";
    position: absolute;
    top: -6px;
    left: 25px;
    z-index: 2;
    border-bottom: 7px solid #f45846;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
  }
}
</style>

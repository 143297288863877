import { notify } from "@kyvg/vue3-notification";
import { ref } from "vue";
import { useStore } from "vuex"

export default function () {

    const store = useStore();
    const errors = ref(null)

    let setErrors = (newErrors) => {
        store.commit("setErrors", newErrors)
        errors.value = newErrors;
        for (let field in errors.value) {
            let inputField = document.querySelector(`input[error=${field}]`)
            if (inputField && inputField != "phone") {
                inputField.classList.add('error')
            }
            else if (field == "phone") {
                let telField = document.querySelector(`div[error=${field}]`)
                if(telField){
                    telField.classList.add('error')
                }
            }
        }
    }
    let clearErrors = () => {
        for (let field in errors.value) {
            let inputField = document.querySelector(`input[error=${field}]`)
            if (inputField && field != "phone") {
                document.querySelector(`input[error=${field}]`).classList.remove('error')
            }
            else if (field == "phone") {
                let telField = document.querySelector(`div[error=${field}]`)
                if (telField) {
                    telField.classList.remove('error')
                }
            }
        }
        store.commit("setErrors", null)
        errors.value = null
    }
    let clearCustomErrors = () => {
        setTimeout(() => {
            clearErrors();
        }, 3000)
    }

    const customUIError = (error) => {
        if (typeof error === "string") notify({ type: "warning", title: error });
    };

    return {
        errors,
        setErrors,
        clearErrors,
        clearCustomErrors,
        customUIError
    }


}
<template>
  <div class="view-container">
    <div class="card">
      <div class="card-header card-header-divider">
        <div class="card-title">Создание уведомления</div>
      </div>
      <div class="card-body">
        <create-notification-form/>
      </div>
    </div>
  </div>
</template>

<script>
import CreateNotificationForm from '../../components/Forms/Notifications/CreateNotificationForm.vue';
export default {
  components: { CreateNotificationForm },
  name: "create-notifications",
};
</script>

<style>
</style>
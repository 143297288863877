<template>
  <div :class="`form-group ${horizontal && 'horizontal'}`">
    <label v-if="label" :for="generatedId">
      {{ label }}
    </label>
    <div class="form-group__input-container">
      <textarea
        :value="modelValue"
        @input="updateValue"
        class="custom-input"
        :id="generatedId"
        :placeholder="placeholder"
        :required="req"
        :error="name"
        tabindex="0"
        :disabled="disabled"
        :style="`resize: ${resize}; min-height: ${height}px;`"
        :maxlength="maxLength"
      />
      <errors :field="name" :errors="errors" />
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, ref } from "@vue/runtime-core";
import Errors from "../../Technical/Errors.vue";
import { useStore } from "vuex";
export default {
  name: "custom-textarea",
  components: { Errors },
  props: {
    modelValue: {
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
    req: {
      default: false,
    },
    disabled: {
      default: false,
    },
    resize: {
      type: String,
      default: "none",
    },
    height: {
      type: Number,
      default: 160,
    },
    horizontal: {
      type: Boolean,
    },
    maxLength: {
      type: Number,
    },
  },
  setup(props, context) {
    const generatedId = ref("textarea");
    const errors = computed(() => store.state.errors);
    const store = useStore();

    const generateId = () => {
      let alphabet = "abcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < 6; i++) {
        generatedId.value +=
          alphabet[Math.round(Math.random() * (alphabet.length - 1))];
      }
    };
    const updateValue = (event) => {
      context.emit("update:modelValue", event.target.value);
    };

    onBeforeMount(() => {
      generateId();
    });

    return {
      generateId,
      generatedId,
      updateValue,
      errors,
    };
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px -5px;
  font-size: 15px;
  font-weight: 400;
  justify-content: flex-end;
  position: relative;

  @media (min-width: 974px) {
    &.horizontal {
      flex-direction: row;

      label {
        padding: 8px 15px 9px 13px;
        flex-basis: 25%;
        flex-grow: 0;
      }

      .form-group__input-container {
        padding: 0 15px 0 0;
      }
    }
  }

  label {
    flex: 1;
    padding: 15px 5px;
    word-break: break-word;
  }

  &__input-container {
    padding: 0 5px;
    width: 100%;
    flex: 1;
    align-self: center;

    textarea {
      padding: 1rem 1.3rem;
    }
  }

  .custom-input::placeholder {
    color: #d3d3d3;
  }

  @media (max-width: 768px) {
    margin: 0 -5px;
  }

  @media (max-width: 973px) {
    .form-control {
      font-size: 16px;
    }
  }
}
</style>